<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select>
                </template>
            </search-filter>
            <Link class="btn-indigo" href="/backend/employeetypes/create" v-if="permission.add > 0">
            <span>Create</span>
            <span class="hidden md:inline">&nbsp; Employee Type</span>
            </Link>
        </div>
        <div class="bg-white rounded-md shadow overflow-x-auto">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3 text-center" width="20">Sl.</th>
                        <th class="pb-2 pt-3 px-3">Name</th>
                        <th class="pb-2 pt-3 px-3">Slug</th>
                        <th class="pb-2 pt-3 px-3 text-center">Status</th>
                        <th class="pb-2 pt-3 px-3 text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(employeetype, index) in employeetypes.data" :key="employeetype.id"
                        class="hover:bg-gray-100 focus-within:bg-gray-100">
                        <td class="border-t pbamenity-1 pt-1 px-3 text-center">
                            {{ index + employeetypes.from }}
                        </td>
                        <td class="border-t">
                            {{ employeetype.employee_type_name }}
                            <icon v-if="employeetype.deleted_at" name="trash"
                                class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                        </td>
                        <td class="border-t pb-1 pt-1 px-3">
                            {{ employeetype.slug }}
                        </td>
                        <td class="border-t pb-1 pt-1 px-3 text-center">
                            <status-level :status="employeetype.status"></status-level>
                        </td>
                        <td class="w-px border-t pb-1 pt-1 px-3 text-center">
                            <div class="flex items-center px-2" v-if="permission.edit > 0">
                                <Link class="px-4 py-2" :href="`/backend/employeetypes/${employeetype.id}/edit`"
                                    tabindex="-1">
                                <i class="fa fa-edit"></i>
                                </Link>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="employeetypes.data.length === 0">
                        <td class="px-3 py-1 border-t" colspan="4">No Employee Type found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="employeetypes.links" />
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        Breadcrumbs,
        StatusLevel
    },
    layout: Layout,
    props: {
        filters: Object,
        employeetypes: Object,
        permission: Object,
    },
    data() {
        return {
            form: {
                search: this.filters.search,
                trashed: this.filters.trashed,
                length: this.filterslength ? this.filters.length : 15,
                status: this.filters.status,
            },
            type: "index",
            title: "Employee Type",
            url: '',
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/employeetypes', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    },
    methods: {
        reset() {
            this.form = mapValues(this.form, () => null)
        },
    },
}
</script>
