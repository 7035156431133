<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select>
                </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl" v-if="permission.add > 0"></Button>
        </div>
        <div class="bg-white rounded-md shadow overflow-x-auto">
            <GroupedTable :services="configs.data" :permission="permission" />
        </div>
        <pagination class="mt-6" :links="configs.links" />
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusLevel from '@/Shared/StatusLevel'
import Button from '@/Shared/Button'

import GroupedTable from './GroupedTable.vue';
export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        StatusLevel,
        Breadcrumbs,
        Button,
        GroupedTable
    },
    layout: Layout,
    props: {
        filters: Object,
        configs: Object,
        permission: Object,
    },

    setup(props) {
        const { filters, configs, permission } = toRefs(props)
        const form = ref({
            search: filters.value.search,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status,
        })
        const type = ref('index')
        const title = ref('Config')
        const url = ref('')
        const createUrl = ref('/backend/payment-configs/create')
        const reset = () => {
            form.value = mapValues(form, () => null)
        }
        return {
            filters,
            configs,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/payment-configs', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    }
}
</script>
