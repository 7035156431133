<template>
    <div>
        <div v-for="(municipality, municipalityName, mainIndex) in groupedData" :key="municipalityName">
            <h2 class="pl-2 text-xl font-bold">{{ mainIndex + 1 }}. {{ municipalityName }}</h2>
            <div v-for="(barangay, barangayName, subIndex) in municipality" :key="barangayName">
                <h3 class="pl-3 text-[14px] font-bold">{{ mainIndex + 1 }}.{{ subIndex + 1 }}. {{ barangayName }}</h3>
                <div v-for="(services, serviceName, serIndex) in barangay" :key="serviceName">
                    <h4 class="pl-3 text-[12px] font-bold">{{ mainIndex + 1 }}.{{ subIndex + 1 }}.{{ serIndex + 1 }}. {{
                        serviceName }}</h4>
                    <table class="w-full whitespace-nowrap">

                        <tbody>
                            <tr v-for="(item, rowIndex) in services" :key="item.id">
                                <td class="border-t pb-1 pt-1 px-3 text-center">{{ rowIndex + 1 }}</td>
                                <td class="border-t pb-1 pt-1 px-3">{{ item.name }}</td>
                                <td class="border-t pb-1 pt-1 px-3">{{ item.amount }}</td>
                                <td class="border-t pb-1 pt-1 px-3 text-center">
                                    <status-level :status="item.status"></status-level>
                                </td>
                                <td class="w-px border-t pb-1 pt-1 px-3 text-center">
                                    <div class="flex items-center px-2" v-if="permission.edit > 0">
                                        <Link class="px-4 py-2"
                                            :href="`/backend/payment-config-barangays/${item.id}/edit`" tabindex="-1">
                                        <i class="fa fa-edit"></i>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Icon from '@/Shared/Icon'
import StatusLevel from '@/Shared/StatusLevel'
export default {
    components: {
        StatusLevel,
        Head,
        Link,
        Icon
    },
    props: {
        services: {
            type: Array,
            required: true
        },
        permission: {
            type: Object,
            required: true
        }
    },
    computed: {
        groupedData() {
            // Grouping by municipality, barangay, and service name
            return this.services.reduce((municipalities, item) => {
                // Group by Municipality
                if (!municipalities[item.municipality_name]) {
                    municipalities[item.municipality_name] = {};
                }

                const municipality = municipalities[item.municipality_name];

                // Group by Barangay
                if (!municipality[item.barangay_name]) {
                    municipality[item.barangay_name] = {};
                }

                const barangay = municipality[item.barangay_name];

                // Group by Service Name
                if (!barangay[item.service_name]) {
                    barangay[item.service_name] = [];
                }
                // Add the item under the appropriate service name
                barangay[item.service_name].push(item);

                return municipalities;
            }, {});
        }
    }
};
</script>

<style>
table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

h2 {
    margin-top: 20px;
    color: #333;
}

h3 {
    margin-top: 10px;
    color: #666;
}
</style>
