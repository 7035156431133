<template>
    <div>
        <div v-for="(servicesByServiceName, serviceGroupName, mainIndex) in groupedData" :key="serviceGroupName">
            <h2 class="pl-2 text-xl font-bold">{{ mainIndex + 1 }}. {{ serviceGroupName }}</h2>
            <div v-for="(services, subGroupName, subIndex) in servicesByServiceName" :key="subGroupName">
                <h3 class="pl-3 text-[14px] font-bold">{{ mainIndex + 1 }}.{{ subIndex + 1 }}. {{ subGroupName }}</h3>
                <table class="w-full whitespace-nowrap">
                    <thead>
                        <tr class="text-left font-bold">
                            <th class="pb-2 pt-3 px-3 text-center">#</th>
                            <th class="pb-2 pt-3 px-3">Particulars</th>
                            <th class="pb-2 pt-3 px-3">Slug</th>
                            <th class="pb-2 pt-3 px-3">Tax Due</th>
                            <th class="pb-2 pt-3 px-3 text-center">Status</th>
                            <th class="pb-2 pt-3 px-3 text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(service, rowIndex) in services" :key="service.id">
                            <td class="border-t pb-1 pt-1 px-3 text-center">{{ rowIndex + 1 }}</td>
                            <td class="border-t pb-1 pt-1 px-3">{{ service.name }}</td>
                            <td class="border-t pb-1 pt-1 px-3">{{ service.slug }}</td>
                            <td class="border-t pb-1 pt-1 px-3">{{ service.amount }}</td>
                            <td class="border-t pb-1 pt-1 px-3 text-center">
                                <status-level :status="service.status"></status-level>
                            </td>
                            <td class="w-px border-t pb-1 pt-1 px-3 text-center">
                                <div class="flex items-center px-2" v-if="permission.edit > 0">
                                    <Link class="px-4 py-2"
                                        :href="`/backend/payment-barangay-configs/${service.id}/edit`" tabindex="-1">
                                    <i class="fa fa-edit"></i>
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Icon from '@/Shared/Icon'
import StatusLevel from '@/Shared/StatusLevel'
export default {
    components: {
        StatusLevel,
        Head,
        Link,
        Icon
    },
    props: {
        services: {
            type: Array,
            required: true
        },
        permission: {
            type: Object,
            required: true
        }
    },
    computed: {
        groupedData() {
            const groupedByServiceName = this.services.reduce((acc, service) => {
                const mainServiceName = service.service_info.service.service_name;
                const subServiceName = service.service_info.service_name;

                if (!acc[mainServiceName]) {
                    acc[mainServiceName] = {};
                }

                if (!acc[mainServiceName][subServiceName]) {
                    acc[mainServiceName][subServiceName] = [];
                }

                acc[mainServiceName][subServiceName].push(service);

                return acc;
            }, {});

            return groupedByServiceName;
        }
    }
};
</script>

<style>
table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

h2 {
    margin-top: 20px;
    color: #333;
}

h3 {
    margin-top: 10px;
    color: #666;
}
</style>
