<template>
    <div class="properties_block">
        <!-- <pre>{{ data }}</pre> -->
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input class="px-2" type="text" v-model="property_form_data.name" placeholder="Enter Name"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Use as Step</label>
            <div class="form-check form-switch">
                <!-- {{ property_form_data.step }} -->
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.step"
                    @change="add_properties" />
            </div>
        </div>
        <div v-if="property_form_data.step" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Step Name</label>
            <div><input class="px-2" type="text" v-model="property_form_data.step_name" placeholder="Step Name"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>External Verification View</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.ex_verifi_view" @change="add_properties" />
            </div>
        </div>

        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Has Dependency</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.dependency"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Printable</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.pritable"
                    @change="add_properties" />
            </div>
        </div>
        <div v-if="property_form_data.dependency" class="grid grid-cols-1 items-center border border-collapse p-2">
            <template v-if="property_form_data.dependency_items.length > 0">
                <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <div class="font-bold">Field Name</div>
                    <div class="font-bold">Value</div>
                </div>
                <div v-for="(item, index) in property_form_data.dependency_items"
                    class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                    <div>
                        <div><input class="px-2" type="text" v-model="property_form_data.dependency_items[index].name"
                                placeholder="Enter Name" @keyup="add_properties" /></div>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                        <div><input class="px-2" type="text" v-model="property_form_data.dependency_items[index].value"
                                placeholder="Enter Value" @keyup="add_properties" /></div>
                        <i v-if="property_form_data.dependency_items.length > 1" class="fa fa-trash-alt"
                            @click="remove_dependency_item(index)"></i>
                    </div>
                </div>
            </template>
            <div class="flex flex-row items-center justify-center gap-2 border border-collapse bg-gray-100 cursor-pointer rounded-md h-6 mt-4"
                @click="add_dependency_item">
                <i class="fa fa-plus"></i>
                <span>Add Dependency Item</span>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Duplication Dependency</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.duplication_dependency" @change="add_properties" />
            </div>
        </div>
        <div v-if="property_form_data.duplication_dependency"
            class="grid grid-cols-1 items-center border border-collapse p-2">
            <template v-if="property_form_data.duplication_dependency_items.length > 0">
                <div class="grid grid-cols-1 items-center border border-collapse gap-2 p-2">
                    <div class="font-bold">Field Name</div>
                </div>
                <div v-for="(item, index) in property_form_data.duplication_dependency_items"
                    class="grid grid-cols-1 items-center border border-collapse gap-2 p-2">
                    <div class="flex flex-row items-center gap-2">
                        <div v-if="index > 0"><select class="text-[10px]" type="text"
                                v-model="property_form_data.duplication_dependency_items[index].opt"
                                @change="add_properties">
                                <option value="&&">AND</option>
                                <option value="||">OR</option>
                            </select></div>
                        <div><input class="px-2" type="text"
                                v-model="property_form_data.duplication_dependency_items[index].name"
                                placeholder="Enter Name" @keyup="add_properties" /></div>
                        <i v-if="property_form_data.duplication_dependency_items.length > 1"
                            class="fa fa-trash-alt text-red-600 text-[10px]"
                            @click="remove_duplication_dependency_item(index)"></i>
                    </div>
                </div>
            </template>
            <div class="flex flex-row items-center justify-center gap-2 border border-collapse bg-gray-100 cursor-pointer rounded-md h-6 mt-4"
                @click="add_duplication_dependency_item">
                <i class="fa fa-plus"></i>
                <span>Add Dependency Item</span>
            </div>
        </div>
        <div v-if="property_form_data.duplication_dependency"
            class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Duplication Alert Message</label>
            <div><input class="px-2" type="text" v-model="property_form_data.duplication_alert_msg"
                    placeholder="Enter Message" @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Workflow</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_workflow"
                    @change="add_properties" />
            </div>
        </div>
        <div v-if="property_form_data.for_workflow"
            class="grid grid-cols-1 items-center border border-collapse gap-2 p-2">
            <label>Choose Designations</label>
            <div class="form-check">
                <div class="flex flex-col mb-2" v-for="item in service_designation_list">
                    <div class="block">
                        <div class="flex flex-row items-center h-4 gap-2 cursor-pointer"
                            @click="add_designation(item.designation_id)">
                            <i v-if="property_form_data.for_wf_designations && property_form_data.for_wf_designations[item.designation_id]"
                                class="fa fa-check-square"></i>
                            <i v-else class="far fa-square"></i>
                            <span>{{ item?.designation?.designation_name }}</span>
                        </div>
                        <div v-if="property_form_data.for_wf_designations && property_form_data.for_wf_designations[item.designation_id]"
                            class="flex flex-row items-center h-4 gap-3 pl-5 cursor-pointer">
                            <div class="flex flex-row items-center h-full gap-1 cursor-pointer"
                                @click="add_designation_permission(item.designation_id, 1)">
                                <i v-if="property_form_data.for_wf_designations && property_form_data.for_wf_designations[item.designation_id] == 1"
                                    class="fa fa-check-circle text-indigo-600"></i>
                                <i v-else class="far fa-circle"></i>
                                <span>View</span>
                            </div>
                            <div class="flex flex-row items-center h-full gap-1 cursor-pointer"
                                @click="add_designation_permission(item.designation_id, 2)">
                                <i v-if="property_form_data.for_wf_designations && property_form_data.for_wf_designations[item.designation_id] == 2"
                                    class="fa fa-check-circle text-indigo-600"></i>
                                <i v-else class="far fa-circle"></i>
                                <span>Edit</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Readonly</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_readonly"
                    @change="add_properties" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
    data: Object,
    service_designation_list: Array
})
const emit = defineEmits(['addProperties', 'addDependencyItem'])
const property_form_data = ref({
    name: props.data && props.data.name ? props.data.name : '',
    step_name: props.data && props.data.step_name ? props.data.step_name : '',
    ex_verifi_view: props.data && props.data.ex_verifi_view ? props.data.ex_verifi_view : false,
    dependency: props.data && props.data.dependency ? props.data.dependency : false,
    pritable: props.data && props.data.pritable ? props.data.pritable : false,
    dependency_items: props.data && props.data.dependency_items ? props.data.dependency_items : [{ name: '', value: '' }],
    duplication_dependency: props.data && props.data.duplication_dependency ? props.data.duplication_dependency : false,
    duplication_dependency_items: props.data && props.data.duplication_dependency_items ? props.data.duplication_dependency_items : [{ name: '' }],
    duplication_alert_msg: props.data && props.data.duplication_alert_msg ? props.data.duplication_alert_msg : '',
    for_workflow: props.data && props.data.for_workflow ? props.data.for_workflow : false,
    for_readonly: props.data && props.data.for_readonly ? props.data.for_readonly : false,
    for_wf_designations: props.data && props.data.for_wf_designations ? props.data.for_wf_designations : {}
})

watch(() => props.data, (updateData, oldValue) => {
    // //console.log('Update Data',updateData, oldValue)
    property_form_data.value = {
        name: updateData && updateData.name ? updateData.name : '',
        step: updateData && updateData.step ? updateData.step : '',
        step_name: updateData && updateData.step_name ? updateData.step_name : '',
        ex_verifi_view: updateData && updateData.ex_verifi_view ? updateData.ex_verifi_view : '',
        dependency: updateData && updateData.dependency ? updateData.dependency : false,
        pritable: updateData && updateData.pritable ? updateData.pritable : false,
        dependency_items: updateData && updateData.dependency_items ? updateData.dependency_items : [{ name: '', value: '' }],
        duplication_dependency: updateData && updateData.duplication_dependency ? updateData.duplication_dependency : false,
        duplication_dependency_items: updateData && updateData.duplication_dependency_items ? updateData.duplication_dependency_items : [{ name: '' }],
        duplication_alert_msg: updateData && updateData.duplication_alert_msg ? updateData.duplication_alert_msg : '',
        for_workflow: updateData && updateData.for_workflow ? updateData.for_workflow : false,
        for_readonly: updateData && updateData.for_readonly ? updateData.for_readonly : false,
        for_wf_designations: updateData && updateData.for_wf_designations ? updateData.for_wf_designations : {}
    }
});

const remove_dependency_item = (index) => {
    property_form_data.value.dependency_items.splice(index, 1)
}

const add_designation = (id) => {
    if (property_form_data.value.for_wf_designations[id]) delete property_form_data.value.for_wf_designations[id]
    else property_form_data.value.for_wf_designations[id] = 1
    add_properties()
}

const add_designation_permission = (id, value) => {
    property_form_data.value.for_wf_designations[id] = value
    add_properties()
}

const add_dependency_item = () => {
    let obj = {
        name: '',
        value: ''
    }
    property_form_data.value.dependency_items.push(obj)
}

const remove_duplication_dependency_item = (index) => {
    property_form_data.value.duplication_dependency_items.splice(index, 1)
}

const add_duplication_dependency_item = () => {
    let obj = {
        name: '',
        value: '',
        opt: '&&'
    }
    property_form_data.value.duplication_dependency_items.push(obj)
}

const add_properties = () => {
    if (!property_form_data.value.step) property_form_data.value.step_name = ''
    emit('addProperties', property_form_data.value)
}
</script>
<style lang="scss" scoped>
.properties_block {
    font-size: 12px;

    input {
        width: 100%;
        height: 26px;
        border: 1px solid #ddd;
        border-radius: 5px;

        &.switch_btn {
            width: 50px;
            height: 26px;
            border-radius: 25px;
            margin-left: -30px;
        }
    }
}
</style>
